<template>
  <div id="app">
    <div class="index">
      <div id="main-1">
        <!-- Header -->
        <header id="header">
          <h1>{{ this.name }}</h1>
          <ul class="icons">
            <li v-if="langcode == 'en'">{{ $t('Address') }}: Shop 103, G/F, Hoi Po Mansion, Riviera Gardens, No. 1 Yi
              Hong Street, Tsuen Wan, New Territories, Hong Kong
            </li>
            <li v-if="langcode == 'zh'">{{ $t('Address') }}: 新界荃灣怡康街 1 號海濱花園 1 座海寶閣地下103 號舖</li>
            <li>{{ $t('Email') }}: meatmekitchenco@outlook.com</li>
            <li>{{ $t('Tel') }}: 852-84814909</li>
          </ul>
          <a href="#" @click="viewAboutUs">
            {{ $t('About Us') }}
          </a>
          <a href="#" @click="viewProducts">
            {{ $t('Our Products') }}
          </a>
          <a href="#" @click="viewFeatures">
            {{ $t('Our Services') }}
          </a>
        </header>

        <!-- Footer -->
        <footer id="footer">
          <a @click="selectLang('zh')" v-if="langcode === 'en'" href="#">中文</a>
          <a @click="selectLang('en')" v-if="langcode === 'zh'" href="#">EN</a>
          <ul class="copyright">
            <li>&copy; MEAT ME KITCHEN</li>
          </ul>
        </footer>
        <VueBotUI :messages="data" @msg-send="messageSendHandler" />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image"
            style="background-position: left center; background-image: url(&quot;images/fulls/01.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>

    <div class="about-us">
      <div id="main-2">
        <!-- Header -->
        <header id="header">
          <h1>{{ $t('About Us') }}</h1>
          <div class="description" v-if="langcode == 'en'">
            {{ this.descEn }}
          </div>
          <div class="description" v-if="langcode == 'zh'">
            {{ this.descZh }}
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page') }}
          </a>
        </header>
        <VueBotUI :messages="data" @msg-send="messageSendHandler" />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image"
            style="background-position: left center; background-image: url(&quot;images/fulls/02.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>

    <div class="our-products">
      <div id="main-3">
        <!-- Header -->
        <header id="header">
          <h1>{{ $t('Our Products') }}</h1>
          <div class="description" v-if="langcode == 'zh'">
            歡迎來到我們的肉類及食品零售專門店！我們致力於為客戶提供新鮮、美味的肉類和其他食品，並提供靈活的購物選擇，以滿足您的需求。<br />
            <ul>
              <li>到店自取<br />
                我們的實體商店提供一個舒適的購物環境，讓您可以親自挑選最新鮮的肉類和其他食品。無論是豬肉、牛肉、雞肉還是各式各樣的配料，我們的產品均經過嚴格挑選，確保品質卓越。您可以在店內與我們的專業員工交流，獲得建議和推薦，選擇最適合您的產品。
              </li>
              <br />
              <li>
                送貨服務<br />
                為了方便您的生活，我們還提供快捷的送貨服務，我們將把新鮮的肉類和其他食品送到您的家中。無論是家庭聚餐、派對還是日常用餐，我們都能確保您在最短時間內收到所需商品，讓您無需擔心購物的繁瑣。
                無論您選擇到店自取還是享受送貨服務，我們都將竭誠為您提供高品質的產品和卓越的服務。期待您的光臨，讓我們一起享受美味生活！
              </li>
            </ul>
          </div>
          <div class="description" v-if="langcode == 'en'">
            Welcome to our meat and food retail specialty store! We are dedicated to providing our customers with fresh
            and delicious meats and other food products, along with flexible shopping options to meet your needs.<br />
            <ul>
              <li>In-store Pickup<br />
                Our physical store provides a comfortable shopping environment where you can personally select the
                freshest meats and other foods. Whether it’s pork, beef, chicken, or a variety of ingredients, all our
                products are carefully selected to ensure top-quality. You can interact with our professional staff
                in-store, receive advice and recommendations, and choose the products that best suit your needs.
              </li>
              <br />
              <li>
                Delivery Service<br />
                To make your life more convenient, we also offer fast delivery services, bringing fresh meats and other
                foods directly to your home. Whether it’s for a family gathering, a party, or everyday meals, we ensure
                that you receive the products you need in the shortest time possible, so you don’t have to worry about
                the hassle of shopping. Whether you choose in-store pickup or opt for our delivery service, we are
                committed to providing you with high-quality products and excellent service. We look forward to your
                visit and hope to help you enjoy a delicious life!
              </li>
            </ul>
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page') }}
          </a>
        </header>
        <VueBotUI :messages="data" @msg-send="messageSendHandler" />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image"
            style="background-position: left center; background-image: url(&quot;images/fulls/03.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>

    <div class="product-features">
      <div id="main-4">
        <!-- Header -->
        <header id="header">
          <h1>{{ $t('Our Services') }}</h1>
          <div class="description" v-if="langcode == 'zh'">
            我們公司致力於提供多元化的購物體驗，滿足不同客戶的需求。以下是我們的三項主要服務：
            <ul>
              <li>外送服務</br>
                我們的外送服務讓您輕鬆享受購物的便利。無論您身處何地，只需輕鬆下單，我們將迅速將商品送到您的門口。無論是日常用品、食品還是其他商品，我們都會確保快速、安全地送達，讓您省下時間，享受生活。
              </li>
              <li>到店取貨</br>
                如果您喜歡親自挑選商品，但又不想浪費時間在商店裡，我們的到店取貨服務正是您的最佳選擇。您可以在線上選擇商品，並選擇在方便的時間到店取貨。這樣不僅節省了等待時間，還能確保您能夠快速拿到心儀的商品。
              </li>
              <li>實體商店購物</br>
                對於喜愛實體購物的客戶，我們的實體商店提供了一個舒適、愉悅的購物環境。在這裡，您可以親自體驗產品、獲得專業建議，並享受與朋友和家人一起購物的樂趣。我們的員工隨時準備為您提供幫助，確保您擁有最佳的購物體驗。
                我們相信，無論您選擇哪種方式，都能在我們這裡找到滿意的商品和服務。期待您的光臨！
              </li>
            </ul>
            （唔怕多訂，最怕無貨。供應商餘下少許現貨。）<br />
            歡迎Whatsapp(8481 4908) 預訂! 勿失良機。
          </div>
          <div class="description" v-if="langcode == 'en'">
            Our company is committed to providing a diverse shopping experience to meet the needs of different
            customers. Here are our three main services:
            <ul>
              <li>Delivery Service</br>
                Our delivery service allows you to easily enjoy the convenience of shopping. No matter where you are,
                simply place an order, and we will swiftly deliver the products to your doorstep. Whether it's daily
                essentials, food, or other items, we ensure quick and safe delivery, saving you time so you can enjoy
                life.
              </li>
              <li>In-store Pickup</br>
                If you prefer to pick out your products in person but don't want to waste time in the store, our
                in-store pickup service is the best option for you. You can choose your items online and select a
                convenient time to pick them up in-store. This not only saves you waiting time but also ensures you get
                the products you want quickly.
              </li>
              <li>In-Store Shopping</br>
                For those who enjoy shopping in a physical store, our stores offer a comfortable and pleasant shopping
                environment. You can experience the products firsthand, receive expert advice, and enjoy shopping with
                friends and family. Our staff is always ready to assist you, ensuring you have the best shopping
                experience.
              </li>
            </ul>
            (Note: Stock is limited, and suppliers have very few items left. Act fast to avoid missing out!)<br />
            Reservation Hotline: WhatsApp 8481 4908. Don't miss out on this opportunity!
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page') }}
          </a>
        </header>
        <VueBotUI :messages="data" @msg-send="messageSendHandler" />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image"
            style="background-position: left center; background-image: url(&quot;images/fulls/04.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { VueBotUI } from 'vue-bot-ui';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  mounted() {
    const langcode = Cookies.get('langcode');
    if (langcode) {
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    }
    this.loadWebsiteContent();
  },
  components: {
    VueBotUI,
  },
  data() {
    return {
      data: [
        {
          agent: 'bot',
          type: 'button',
          text: 'Hello, welcome to MEAT ME KITCHEN. How can I help you? ',
          disableInput: false,
        }
      ],
      replyTexts: [
        {
          'question': 'business_hour',
          'question_text': 'Your business hour is?',
          'keywords': ['business hour'],
          'answer': "Our Business Hour is 10:00am - 17:00pm.",
        },
        {
          'question': 'company address',
          'question_text': 'Where is your company?',
          'keywords': ['where'],
          'answer': "Our Company is at Shop 103, G/F, Hoi Po Mansion, Riviera Gardens, No. 1 YiHong Street, Tsuen Wan, New Territories, Hong Kong.",
        },
        {
          'question': 'place_order',
          'question_text': 'How to contact?',
          'keywords': ['contact'],
          'answer': 'If you have any questions about us, please call 852-69981318 for further follow-up after receiving the goods.',
        },
        {
          'question': 'product_source',
          'question_text': 'Your product come from?',
          'keywords': ['from'],
          'answer': "Our product come from different place, such as Hong Kong, USA or Japan.",
        },
        {
          'question': 'order_instruction',
          'question_text': 'How to orders?',
          'keywords': ['order'],
          'answer': "If you want to order product from us, please feel free to contact us with our phone or email address.",
        },
        {
          'question': 'product_list',
          'keywords': ['product list'],
          'question_text': 'Please give me the product list.',
          'answer': "Currently, we do not had an online product list. If had any question about it, please contact with us.",
        },
      ],
      name: '',
      descEn: '',
      descZh: '',
      contact: '',
    }
  },
  methods: {
    viewAboutUs() {
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.about-us');
      about.style.display = 'block';
    },
    viewProducts() {
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.about-us');
      about.style.display = 'none';
      const products = document.querySelector('.our-products');
      products.style.display = 'block';
    },
    viewFeatures() {
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.about-us');
      about.style.display = 'none';
      const products = document.querySelector('.our-products');
      products.style.display = 'none';
      const features = document.querySelector('.product-features');
      features.style.display = 'block';
    },
    backIndex() {
      const index = document.querySelector('.index');
      index.style.display = 'block';
      const about = document.querySelector('.about-us');
      about.style.display = 'none';
      const products = document.querySelector('.our-products');
      products.style.display = 'none';
      const features = document.querySelector('.product-features');
      features.style.display = 'none';
    },
    async loadConfig() {
      return new Promise(async (resolve, reject) => {
        const baseApiUrl = process.env.VUE_APP_API_URL;
        const apiUrl = `${baseApiUrl}/website/content`;
        const fetchResult = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          },
        });

        const status = await fetchResult.status;

        //If cannot connect with API server
        if (status !== 200 && status !== 401) {
          const err = 'Cannot load system config. Maybe there are some network problem?';
          reject(err);
          return;
        }

        //If user do not login or no permission
        if (status === 401) {
          const err = 'Sorry, You don\'t had permission to access it.';
          reject(err);
          return;
        }

        const resultJson = await fetchResult.json();

        resolve(resultJson);
      });
    },
    async loadWebsiteContent() {
      try {
        let websiteContent = await this.loadConfig();

        this.name = websiteContent['website_name'];
        this.descZh = websiteContent['website_introduce_zh'];
        this.descEn = websiteContent['website_introduce_en'];
        this.contact = websiteContent['company_contact'];

      } catch (err) {
        console.log(err);
      }
    },
    selectLang(langcode) {
      Cookies.set('langcode', langcode);
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    },
    messageSendHandler(userAction) {
      const searchKeywords = this.searchKeywords;

      let question = userAction['value'];
      if (!question) {
        question = userAction['text'].toLowerCase();
      }
      let answer = this.replyTexts.find(function (item) {
        if (item.question === question) {
          return true;
        }
        if (item.question_text === question) {
          return true;
        }
        if(searchKeywords(item.keywords, question)){
          return true;
        }
      })
      const userQuestion = {
        agent: 'user',
        type: 'text',
        text: userAction['text'],
        disableInput: false,
      }
      this.data.push(userQuestion);

      if (answer) {
        answer = answer['answer'];
        const reply = {
          agent: 'bot',
          type: 'text',
          text: answer,
          disableInput: false,
        }
        this.data.push(reply);
      } else {
        const reply = {
          agent: 'bot',
          type: 'button',
          text: 'Hello, Welcome to our website. What will you want to ask? :',
          disableInput: false,
          options: [
            {
              text: 'Where your product come from ?',
              value: 'product_source',
              action: 'postback' // Request to API
            },
            {
              text: 'Your business hour is?',
              value: 'business_hour',
              action: 'postback' // Request to API
            },
            {
              text: 'How could I view the product list?',
              value: 'product_list',
              action: 'postback' // Request to API
            },
            {
              text: 'Ordering instructions',
              value: 'order_instruction',
              action: 'postback' // Request to API
            },
            {
              text: 'Contact Method',
              value: 'place_order',
              action: 'postback' // Request to API
            },
          ],
        }
        this.data.push(reply);
      }
    },
    searchKeywords(keywords, text) {
      const re = new RegExp('(' + keywords.join('|') + ')', 'g')
      const result = text.toLowerCase().match(re)
      if (result != null || result != null) {
        return true;
      }
      return false;
    },
    ...mapActions(['changeLang']),
  },
  computed: mapState({
    langcode: state => state.langcode,
  }),
}
</script>

<style lang="scss">
h1 {
  text-align: left;
}

body.is-preload-1 #main {
  opacity: 1;
  right: 0;
}

.qkb-board {
  padding-bottom: 30px;
  width: 500px;
}

#viewer .slide .image {
  @media screen and (min-width: 1300px) {
    background-size: 100%;
  }
}

#header {
  a {
    display: inline-block;
    margin-right: 10px;
  }
}

.about-us,
.our-products,
.product-features {
  display: none;
}

.description {
  text-align: left;
  margin-bottom: 20px;
}
</style>
